<template>
 <div class="contractlist">
     <NavBar :list="navList" name="合同设置"></NavBar>
     <router-view></router-view>
 </div>
</template>
<script>
export default {
    data() {
        return {
            navList: [{
                    name: "模板设置",
                    path: "/contract/contractSetting/contractsSetting"
                }, {
                    name: "功能设置",
                    path:'/contract/contractSetting/contractfnsetting'
                }],
            form: {
                starttime: "",
                merchantName: "",
                endtime: "",
                status: ""
            },
            addform:{name:'',note:'',list:''},
            addvisible: false,
            contractstatus: [{
                    key: 0,
                    title: "待审批"
                }, {
                    key: 1,
                    title: "已拒绝"
                }, {
                    key: 2,
                    title: "待商户签字"
                }, {
                    key: 3,
                    title: "商户已签字"
                }, {
                    key: 4,
                    title: "正常"
                }, {
                    key: 5,
                    title: "即将到期"
                }, {
                    key: 6,
                    title: "已到期"
                }, {
                    key: 7,
                    title: "已续签"
                }
            ]
        };
    }
}
</script>
<style lang='scss'>
</style>
